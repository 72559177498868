const ar = {
  welcomeMessage: 'Welcome aboard',
  home: 'Home',
  najizPortal: 'Najiz Portal',
  judicalEServices: 'The unified Judical electronic services',
  enter: 'Enter',
  noServicesFoundPleaseTryAgain: 'No services found.',
  new: 'New',
  modified: 'Modified',

  verificationServices: {
    title: 'Verification services',
    description: 'This service enables you to verify documents issued by the Ministry of Justice electronically',
    userNeed: 'I want to check',
    browseVerificationServices: 'Browse verification services',
  },

  appInsights: {
    verificationServicesArName: 'Verification Services',
  },

  serviceUsage: 'Service usage',
  termsAndConditions: 'Terms and conditions',
  privacyPolicy: 'Privacy policy',
  mostUsed: 'Most used',
  serviceExplaination: 'Service explaination',
  najizInNumbers: 'Najiz in numbers',
  najizData: 'Najiz data',
  last7Days: 'last 7 days',
  najizDataDescription: 'The ideal solution for requesting reliable data and creating new business opportunities',
  najizDataLogin: 'Login to Najez Data',

  services: {
    title: 'E-services',
    explaination: 'Service Explaination',
    browseAllServices: 'Browse all services',
    typeHereToSearch: 'Type here to search',
  },

  licenseType: 'License type',
  licenseGuide: 'License guide',
  chooseLicenseType: 'Choose license type',
  iWantToPreview: 'I want to preview',

  faqs: {
    title: 'Faqs',
    searchPlaceholder: 'Search here, for example: How to create a claim sheet',
    howCanWeHelpYou: 'How can we help you ?',
    browseAllQuestions: 'Browse all questions',
    noQuestionsAvailable: 'No questions available.',
    shareQuestion: 'Share question',
    questionLinkCopiedSuccessfully: 'Question link copied successfully',
    questionDetails: 'Question details',
    questionDetailsNotAvailable: 'Question details not available',
  },

  najizPartners: 'Najiz partners',

  contactUs: {
    najizCenterTitle: 'Najez center for legal services',
    description: 'Model justice centers within the National Transformation Program to improve the beneficiaries’ experience and serve the business sector.',
    title: 'Address',
    address: 'Al-Malqa, Anas Bin Malik Road, Riyadh 13524',
    email: 'Email',
    emailAddress: '1950@moj.gov.sa',
    telephone: 'Phone',
    telephoneNumber: '1950',
  },
  mobileApplication: {
    title: 'تطبيق ناجز',
    text: 'يمكنكم تحميل تطبيق ناجز للأجهزة الذكية التي تعمل بنظامي أندرويد وأيفون',
  },

  useTheService: 'Use the service',
  serviceDescription: 'Service description',
  serviceRequirments: 'Service requirments',
  stepsToApplyForTheService: 'Steps to apply for the service',
  submitNewRequest: 'Submit a new request',
  followUpOnPreviousRequest: 'Follow up on previous request',
  authenticationOfCompletedRequest: 'Authentication of completed request',
  serviceVideoExplaination: 'Service video explaination',
  serviceProviderChannels: 'Service provider channels',
  youShouldKnow: 'You should know',
  fetchingData: 'Fetching data in progress ...',
  searchResult: 'Search result',
  viewSearchResults: 'View search results about "{searchText}" | {count} in ',
  browseService: 'Browse service',
  noSearchResultsFound: 'No search results found',

  login: 'Login',
  individuals: 'Individuals',
  businesses: 'Businesses',
  government: 'Government',
  lawyers: 'Lawyers',
  individualsLoginMsg: 'Login for individuals including citizens, residents and visitors',
  businessesLoginMsg: 'Login for owners and representatives of companies, institutions and public benefit facilities',
  governmentLoginMsg: 'Login for representatives of government agencies',
  lawyersLoginMsg: 'Login for lawyers licensed by the Ministry of Justice',

  serverErrors: {
    USER_NOT_FOUND: 'User is not found, please try again',
    INVALID_DATA: 'INVALID DATA, please try again',
  },
};

export default ar;
