import 'izitoast/dist/css/iziToast.min.css';
import iZtoast, { IziToastSettings } from 'izitoast';
// services
import i18n from '@/plugins/i18n';

const getOptions = (configs: any) => ({
  position: 'bottomLeft',
  rtl: true,
  ...configs,
});

export class Toaster {
  public static error(configs: IziToastSettings) {
    return iZtoast.error(getOptions({
      title: i18n.t('error').toString(),
      ...configs,
    }));
  }

  public static success(configs: IziToastSettings) {
    return iZtoast.success(getOptions({
      title: i18n.t('success').toString(),
      ...configs,
    }));
  }
}
