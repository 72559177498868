const ar = {
  welcomeMessage: 'مرحباً بك ',
  home: 'الرئيسية',
  najizPortal: 'منصة ناجز',
  judicalEServices: 'المنصة الموحدة للخدمات العدلية الإلكترونية',
  enter: 'الدخول',
  noServicesFoundPleaseTryAgain: 'لا توجد خدمات.',
  new: 'جديد',
  modified: 'معدلة',

  verificationServices: {
    title: 'خدمات التحقق',
    description: 'تمكنك هذه الخدمة من التحقق من الوثائق الصادرة من وزارة العدل إلكترونياً',
    userNeed: 'أريد التحقق من',
    browseVerificationServices: 'تصفح خدمات التحقق',
  },

  appInsights: {
    verificationServicesArName: 'خدمات التحقق',
  },

  serviceUsage: 'استخدام الخدمة',
  termsAndConditions: 'الشروط والأحكام',
  privacyPolicy: 'سياسة الخصوصية',
  mostUsed: 'الأكثر استخداماً',
  serviceExplaination: 'شرح الخدمة',

  services: {
    title: 'الخدمات الإلكترونية',
    explaination: 'شرح الخدمة',
    browseAllServices: 'تصفح جميع الخدمات',
    typeHereToSearch: 'اكتب هنا للبحث',
  },

  licenseType: 'نوع الترخيص',
  licenseGuide: 'دليل المرخصين',
  chooseLicenseType: 'اختر نوع الترخيص',
  iWantToPreview: 'أريد استعراض دليل ',

  najizInNumbers: 'ناجز بالأرقام',
  najizData: 'ناجز بيانات',
  last7Days: 'اخر 7 أيام',
  najizDataDescription: 'الحل الأمثل لطلب  البيانات الموثوقة وخلق فرص جديدة لأعمال',
  najizDataLogin: 'دخول لناجز بيانات',

  faqs: {
    title: 'الأسئلة المتكررة',
    searchPlaceholder: 'ابحث هنا، مثلاً: كيفية إنشاء صحيفة دعوى',
    howCanWeHelpYou: 'كيف يمكننا مساعدتك؟',
    browseAllQuestions: 'تصفح جميع الأسئلة',
    noQuestionsAvailable: 'لا توجد أسئلة.',
    shareQuestion: 'مشاركة السؤال',
    questionLinkCopiedSuccessfully: 'تم نسخ رابط السؤال بنجاح',
    questionDetails: 'تفاصيل السؤال',
    questionDetailsNotAvailable: 'بيانات السؤال غير متاحة',
  },

  najizPartners: 'شركاء ناجز',

  contactUs: {
    najizCenterTitle: 'مركز ناجز للخدمات العدلية',
    description: 'مراكز عدلية نموذجية ضمن برنامج التحول الوطني لتحسين تجربة المستفيدين وخدمة قطاع الأعمال.',
    title: 'العنوان',
    address: 'الملقا, طريق أنس بن مالك الرياض 13524',
    email: 'البريد الإلكتروني',
    emailAddress: '1950@moj.gov.sa',
    telephone: 'الهاتف',
    telephoneNumber: '1950',
  },
  mobileApplication: {
    title: 'تطبيق ناجز',
    text: 'يمكنكم تحميل تطبيق ناجز للأجهزة الذكية التي تعمل بنظامي أندرويد وأيفون',
  },

  useTheService: 'استخدم الخدمة',
  serviceDescription: 'وصف الخدمة',
  serviceRequirments: 'متطلبات الخدمة',
  stepsToApplyForTheService: 'خطوات التقديم على الخدمة',
  submitNewRequest: 'تقديم طلب جديد',
  followUpOnPreviousRequest: 'متابعة طلب سابق',
  authenticationOfCompletedRequest: 'مصادقة طلب مكتمل',
  serviceVideoExplaination: 'فيديو شرح الخدمة',
  serviceProviderChannels: 'قنوات تقديم الخدمة',
  youShouldKnow: 'يجب أن تعرف',

  fetchingData: 'جاري تحميل البيانات ...',
  searchResult: 'نتيجة البحث',
  viewSearchResults: 'عرض نتائج البحث عن "{searchText}" | {count} نتيجة في',
  browseService: 'تصفح الخدمة ',
  noSearchResultsFound: 'لا توجد بيانات متاحة',

  login: 'تسجيل الدخول',
  individuals: 'أفراد',
  businesses: 'أعمال',
  government: 'حكومة',
  lawyers: 'محامين',
  individualsLoginMsg: 'تسجيل الدخول للأفراد من مواطنين ومقيمين وزوار',
  businessesLoginMsg: 'تسجيل الدخول لملاك وممثلي الشركات والمؤسسات ومنشأت النفع العام',
  governmentLoginMsg: 'تسجيل الدخول لممثلي الجهات الحكومية',
  lawyersLoginMsg: 'تسجيل الدخول للمحامين المرخصين من قبل وزارة العدل',

  serverErrors: {
    USER_NOT_FOUND: 'المستخدم غير موجود الرجاء المحاولة مرة أخرى',
    INVALID_DATA: 'حدث خطأ الرجاء المحاولة مرة أخرى',
  },
};

export default ar;
