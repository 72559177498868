import { Toaster } from './toast';

export class ErrorHandler {
  public static errorResponseHandler(error: any) {
    let message = '';

    const isSystemError = error?.response?.status === 500;
    message = isSystemError ? 'حدث خطا ما في النظام' : error.response.data;

    window.HideLoader();

    if (message) {
      Toaster.error({ message });
    }

    return Promise.reject(error);
  }
}
