<div>
  <v-app>
    <v-main>

      <breadcrumb />
      
      <router-view />
    </v-main>
  </v-app>
</div>
