import { Service } from 'vue-di-container';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// models
// Helpers
import router from '@/router';
import { trackEventConfigs } from '@/models/telemetry';

@Service()
export default class TelemetryService {
  private appInsights: ApplicationInsights;
  private serviceNameAr: string;
  private serviceNameEn: string;
  constructor() {
    const {
      InstrumentationKey,
      ApplicationName,
      ServiceNameAr,
      ServiceNameEn,
    } = window.$config.ApplicationInsights;

    this.serviceNameAr = ServiceNameAr;
    this.serviceNameEn = ServiceNameEn;

    const options: any = {
      id: InstrumentationKey,
      routerValue: router,
      trackInitialPageView: true,
      onAfterScriptLoaded: (data: any) => {
        data.addTelemetryInitializer(
          (envelope: any) => {
            envelope.data.applicationName = ApplicationName;
            if (typeof (envelope.baseData) === 'string') {
              const name = envelope.baseData;
              envelope.baseData = {};
              envelope.baseData.name = name;
            }
          },
        );
      },
    };

    const config = options.appInsightsConfig || {};
    config.instrumentationKey = config.instrumentationKey || options.id;

    if (options.appInsights) {
      this.appInsights = options.appInsights;
    } else {
      this.appInsights = new ApplicationInsights({ config });
      this.appInsights.loadAppInsights();

      if (typeof (options.onAfterScriptLoaded) === 'function') {
        options.onAfterScriptLoaded(this.appInsights);
      }
    }

    const routerlocal = options.routerValue;

    // Watch route event if router option is defined.
    if (routerlocal) {
      if (options.trackInitialPageView) {
        this.setupPageTracking(options);
      } else {
        routerlocal.onReady(() => this.setupPageTracking(options));
      }
    }
  }

  public async trackEvent(configs: trackEventConfigs) {
    const {
      userInfo = null,
      eventName = '',
      isSuccess = true,
      serviceType = '',
      serviceNameAr = '',
      serviceNameEn = '',
    } = configs;

    let userId = 'Anonymous';
    let nationality = 'Anonymous';
    let age = 'Anonymous';
    let gender = 'Anonymous';

    if (userInfo) {
      const {
        userId: id,
        nationalityName,
        age: userAge,
        gender: userGender,
      } = userInfo;

      userId = id;
      nationality = nationalityName || '';
      age = String(userAge);
      gender = (userGender === '1' ? 'M' : 'F');
    }

    this.appInsights.trackEvent({ name: `mojCE.Landing.${eventName}` }, {
      userId: `${userId}`,
      nationality: `${nationality}`,
      age: `${age}`,
      gender: `${gender}`,
      serviceNameAr: serviceNameAr || this.serviceNameAr,
      serviceName: serviceNameEn || this.serviceNameEn,
      serviceType,
      isSuccessful: isSuccess,
    });
  }

  private setupPageTracking(options: any) {
    const { routerValue } = options;
    const baseName = options.baseName || '(Vue App)';

    routerValue.beforeEach((route: any, from: any, next: any) => {
      const name = `${baseName} / ${route.name}`;
      this.appInsights.startTrackPage(name);
      next();
    });

    routerValue.afterEach((r: { name: string; fullPath: string; }) => {
      const name = `${baseName} / ${r.name}`;
      const url = `${location.protocol}//${location.host}${r.fullPath}`;
      this.appInsights.stopTrackPage(name, url);
      this.appInsights.flush();
    });
  }
}
