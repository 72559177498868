import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
//  Guards
// router.beforeEach((to, from, next) => {
//   if (!to.matched.length) {
//     window.location.href = '/NotFound';
//   } else {
//     next();
//   }
// });

export default router;
