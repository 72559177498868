import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
// Locals
import ar from 'vuetify/src/locale/ar';
import en from 'vuetify/src/locale/en';

import {
  VApp,
  VMain,
  VBtn,
  VCol,
  VRow,
  VImg,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VIcon,
  VSpacer,
  VSheet,
  VSlideGroup,
  VSlideItem,
  VContainer,
  VTextField,
  VSelect,
  VRadio,
  VMenu,
  VList,
  VTabs,
  VTab,
  VTabItem,
  VTabsItems,
  VListItem,
  VListItemTitle,
  VBreadcrumbs,
  VBreadcrumbsItem,
  VExpansionPanel,
  VExpansionPanels,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VCarousel,
  VCarouselItem,
  VDivider,
} from 'vuetify/lib/components';

Vue.use(Vuetify, {
  components: {
    VApp,
    VBtn,
    VCol,
    VRow,
    VImg,
    VCard,
    VMain,
    VIcon,
    VSpacer,
    VCardTitle,
    VCardText,
    VContainer,
    VTextField,
    VSheet,
    VRadio,
    VSlideGroup,
    VCardActions,
    VSelect,
    VBreadcrumbs,
    VSlideItem,
    VMenu,
    VList,
    VListItem,
    VTabs,
    VTab,
    VTabItem,
    VTabsItems,
    VListItemTitle,
    VBreadcrumbsItem,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VCarousel,
    VCarouselItem,
    VDivider,
  },
});

export default new Vuetify({
  rtl: true,
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { ar, en },
    current: 'ar',
  },
  theme: {
    themes: {
      light: {
        primary: '#158E8D',
        secondary: '#e0cf97',
      },
    },
  },
});
