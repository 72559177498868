import { RouteConfig } from 'vue-router';
import Breadcrumbs from './breadcrumbs';

const Home = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Home" */ '@/views/Home');
const FAQs = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "FAQs" */ '@/views/FAQs/faqs');
const SharedQuestionDetails = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "SharedQuestionDetails" */ '@/views/FAQs/QuestionDetails/questionDetails');
const TermsAndConditions = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "TermsAndConditions" */ '@/views/TermsAndConditions/termsAndConditions');
const PrivacyPolicy = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "PrivacyPolicy" */ '@/views/PrivacyPolicy/privacyPolicy');
const LicenseGuide = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "LicenseGuide" */ '@/views/LicenseGuide/licenseGuide');
const VerificationServices = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "VerificationServices" */ '@/views/VerificationServices/verificationServices');
const Services = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "E-services" */ '@/views/Services/services');
const ServiceDetails = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "ServiceDetails" */ '@/views/ServiceDetails/serviceDetails');
const SmartSearchResult = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "ServiceDetails" */ '@/views/SmartSearchResult/smartSearchResult');

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/terms-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    meta: {
      breadcrumb: Breadcrumbs.termsAndConditions,
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      breadcrumb: Breadcrumbs.privacyPolicy,
    },
  },
  {
    path: '/verification-services',
    name: 'VerificationServices',
    component: VerificationServices,
    meta: {
      breadcrumb: Breadcrumbs.verificationServices,
    },
  },
  {
    path: '/e-services',
    name: 'Services',
    component: Services,
    meta: {
      breadcrumb: Breadcrumbs.services,
    },
  },
  {
    path: '/e-services/:serviceId',
    name: 'ServiceDetails',
    component: ServiceDetails,
    meta: {
      highlighted: true,
      breadcrumb: Breadcrumbs.serviceDetails,
    },
  },
  {
    path: '/license-guide',
    name: 'LicenseGuide',
    component: LicenseGuide,
    meta: {
      breadcrumb: Breadcrumbs.licenseGuide,
    },
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: FAQs,
    meta: {
      breadcrumb: Breadcrumbs.faqs,
    },
  },
  {
    path: '/faqs/:id',
    name: 'SharedQuestionDetails',
    component: SharedQuestionDetails,
    meta: {
      highlighted: true,
      breadcrumb: Breadcrumbs.sharedQuestionDetails,
    },
  },
  {
    path: '/search/:searchText',
    name: 'smart-search-result',
    component: SmartSearchResult,
    meta: {
      breadcrumb: Breadcrumbs.searchResult,
    },
  },
  // ? : for old routes
  {
    path: '/services',
    name: 'old-services',
    component: Services,
    meta: {
      breadcrumb: Breadcrumbs.services,
    },
  },
  {
    path: '/service/:serviceId',
    name: 'old-ServiceDetails',
    component: ServiceDetails,
    meta: {
      highlighted: true,
      breadcrumb: Breadcrumbs.serviceDetails,
    },
  },
  {
    path: '/faq',
    name: 'old-FAQs',
    component: FAQs,
    meta: {
      breadcrumb: Breadcrumbs.faqs,
    },
  },
  {
    path: '/faq/:id',
    name: 'old-SharedQuestionDetails',
    component: SharedQuestionDetails,
    meta: {
      highlighted: true,
      breadcrumb: Breadcrumbs.sharedQuestionDetails,
    },
  },
] as RouteConfig[];

export default routes;
