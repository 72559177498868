<div v-if="showBreadCrumb" :class="[styles.breadcrumbsWrapper, {[styles.highlighted]: isHighlighted}]">
  <v-container >
    <v-breadcrumbs class="pa-0" :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <a
          :aria-label="item.text"
          v-if="item.external" 
          :href="item.href" 
          :disabled="item.disabled"
        >
          {{item.text}}
        </a>
  
        <v-breadcrumbs-item
          v-else
          :aria-label="item.text"
          :class="[styles.activeBreadcrumb, {[styles.disabled]: item.disabled}]"
          @click="onClickBreadcrumb(item)"
          :disabled="item.disabled"
        >
          {{item.text}}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-container>
</div>