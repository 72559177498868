import { AppFeatures } from '@/models/feature';
import { Service } from 'vue-di-container';
import router from '@/router';
import { FeaturesNames } from '@/enums/FeaturesNames';
import { Route, RouteRecord } from 'vue-router';

@Service()
export default class FeatureToggleService {
  features!:AppFeatures

  constructor() {
    this.features = this.getFeatures();

    router.onReady(() => {
      if (!this.authorizeRouteFeature(router.currentRoute)) {
        /**
         * TODO replace location with router.push
         * current state router.push throw error on hashparams
         */
        location.href = router.resolve({ name: 'NotFound' }).href;
      }
    });

    this.mountFeaturesRoutingGuard();
  }

  getFeatures():AppFeatures {
    return window.$config.features;
  }

  authorizeRouteFeature(route:Route):boolean {
    if (!this.validateFeatureRoute(route)) {
      return false;
    }
    if (!this.validateFeatureRouteHashParams(route)) {
      return false;
    }
    if (!this.validateMatchedRoutes) {
      return false;
    }
    return true;
  }
  validateMatchedRoutes(matchedRoutes:RouteRecord[]) {
    const isInvalidFeaturesExist = matchedRoutes.some((route) => !this.validateFeatureRoute(route));
    return !isInvalidFeaturesExist;
  }

  validateFeatureRoute(route:RouteRecord | Route) {
    let isFeatureEnabled = true;

    if (route.meta?.feature as FeaturesNames) {
      if (this.features[route.meta?.feature as FeaturesNames] === false) {
        isFeatureEnabled = this.isFeatureEnabled(route.meta?.feature);
      }
    }

    return isFeatureEnabled;
  }
  validateFeatureRouteHashParams(route:Route) {
    let isFeatureEnabled = true;
    if (route.meta?.feature && route.meta?.feature.hashes) {
      const featureHash:{[key:string]:FeaturesNames} = route.meta?.feature.hashes.find((hash:{[key:string]:FeaturesNames}) => Object.keys(hash)[0] === route.hash.substring(1) && Object.values(hash).length);
      if (featureHash) {
        isFeatureEnabled = this.isFeatureEnabled(Object.values(featureHash)[0]);
      }
    }
    return isFeatureEnabled;
  }

  mountFeaturesRoutingGuard() {
    router.beforeEach((to, from, next) => {
      if (!this.authorizeRouteFeature(to)) {
        return next({ name: 'NotFound' });
      }

      return next();
    });
  }
  toEnabledFeaturesModel<T extends object & {features?: FeaturesNames | FeaturesNames[]} = {features: FeaturesNames | FeaturesNames[]}>(list:T[]) {
    return list.filter((listModel) => (listModel.features ? this.isFeatureEnabled(listModel.features) : true));
  }

  isFeatureEnabled(feature : FeaturesNames | FeaturesNames[]):boolean {
    if (Array.isArray(feature)) {
      return (feature as FeaturesNames[]).some((featureName) => this.features[featureName]);
    }

    return this.features[feature as FeaturesNames];
  }
}
