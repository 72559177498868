import { BreadCrumbModel } from '@/models/breadcrumb';
import i18n from '@/plugins/i18n';

const Breadcrumbs = {
  verificationServices: [{
    text: i18n.t('verificationServices.title'),
    href: '/verification-services',
  }] as BreadCrumbModel[],

  termsAndConditions: [{
    text: i18n.t('termsAndConditions'),
    href: '/terms-conditions',
  }] as BreadCrumbModel[],

  privacyPolicy: [{
    text: i18n.t('privacyPolicy'),
    href: '/privacy-policy',
  }] as BreadCrumbModel[],

  services: [{
    text: i18n.t('services.title'),
    href: '/e-services',
  }] as BreadCrumbModel[],

  serviceDetails: [
    {
      text: i18n.t('services.title'),
      href: '/e-services',
    },
    {
      text: i18n.t('services.explaination'),
      href: '/e-services',
    },
  ] as BreadCrumbModel[],

  licenseGuide: [{
    text: i18n.t('licenseGuide'),
    href: '/license-guide',
  }] as BreadCrumbModel[],

  faqs: [{
    text: i18n.t('faqs.title'),
    href: '/faqs',
  }] as BreadCrumbModel[],

  sharedQuestionDetails: [
    {
      text: i18n.t('faqs.title'),
      href: '/faqs',
    },
    {
      text: i18n.t('faqs.questionDetails'),
      href: '/faqs',
    },
  ] as BreadCrumbModel[],

  searchResult: [{
    text: i18n.t('searchResult'),
    href: '/',
  }] as BreadCrumbModel[],
};

export default Breadcrumbs;
