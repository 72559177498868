import Vue from 'vue';
import './registerServiceWorker';
import router from './router';

import { VueDiContainer } from 'vue-di-container';

// Plugins
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
// Components
import App from './views/App/app';
// styles
import '../public/styles/main.scss';
import AxiosService from './services/axios';
import LoaderService from './services/loader';
import initializeApplication from './plugins/application-initialization';
import TelemetryService from './services/TelemetryService';
import FeatureToggleService from '@/services/featureToggle';

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== 'production';
Vue.use(VueDiContainer);

declare module 'vue/types/vue' {
  interface Vue {
    $appInsights: any;
  }
}

declare global {
  interface Window {
    $config: any;
    $disableNewRequestsPeriod: any;
    ShowLoader(): void;
    HideLoader(): void;
    ApplePaySession: any,

  }
}

initializeApplication().then(() => {
  new Vue({
    i18n,
    router,
    vuetify,
    diProvide: [
      AxiosService,
      LoaderService,
      TelemetryService,
      FeatureToggleService,
    ],
    render: (h) => h(App),
  }).$mount('#app');
});
